<th></th>
<th>
  <a (click)="sortBy('name')">
    <span>{{ "homepage.lists.column_structure" | translate }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'name'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('updated_at')">
    <span>{{ "homepage.lists.column_updated_at" | translate }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'updated_at'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('sector_name')">
    <span>{{ "homepage.lists.column_sector_name" | translate }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'sector_name'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('created_at')">
    <span>{{ "homepage.lists.column_creation" | translate }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'created_at'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
