<th></th>
<th>
  <a (click)="sortBy('name')">
    <span>{{ "homepage.cards.knowledges.placeholder_name" | translate }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'name'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('author')">
    <span>{{
      "homepage.cards.knowledges.placeholder_author" | translate
    }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'author'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('contributors')">
    <span>{{
      "homepage.cards.knowledges.placeholder_contributors" | translate
    }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'contributors'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
<th>
  <a (click)="sortBy('knowledges')">
    <span>{{
      "homepage.cards.knowledges.placeholder_entry_number" | translate
    }}</span>
    <i
      class="fa"
      [ngClass]="[
        sortValue === 'knowledges'
          ? 'fa-caret-' + sortOrder
          : 'fa-caret-down deactivate'
      ]"
    ></i>
  </a>
</th>
